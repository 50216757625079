import { Box, Typography } from '@mui/material';

import Tooltip from '../Tooltip';

const SectionHeading = ({ headingText, tooltipText }) => {
  return (
    <Box display="flex" alignItems="center" mb="20px">
      <Typography variant="h2">{headingText}</Typography>
      <Tooltip title={tooltipText} />
    </Box>
  );
};

export default SectionHeading;
