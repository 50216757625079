export const firebaseConfig = {
  apiKey:
    process.env.REACT_APP_ENV === "PROD"
      ? process.env.REACT_APP_FIREBASE_API_KEY
      : process.env.REACT_APP_FIREBASE_API_KEY_DEV,
  authDomain:
    process.env.REACT_APP_ENV === "PROD"
      ? process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
      : process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_DEV,
  projectId:
    process.env.REACT_APP_ENV === "PROD"
      ? process.env.REACT_APP_FIREBASE_PROJECT_ID
      : process.env.REACT_APP_FIREBASE_PROJECT_ID_DEV,
  storageBucket:
    process.env.REACT_APP_ENV === "PROD"
      ? process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
      : process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DEV,
  messagingSenderId:
    process.env.REACT_APP_ENV === "PROD"
      ? process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
      : process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_DEV,
  appId:
    process.env.REACT_APP_ENV === "PROD"
      ? process.env.REACT_APP_FIREBASE_APP_ID
      : process.env.REACT_APP_FIREBASE_APP_ID_DEV,
};
