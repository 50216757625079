import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Box, Container, Snackbar, Grid, Button } from '@mui/material';

import { getUser, useDeviceDashboardData } from '@apis';
import { useDetectOutsideClick } from '@hooks';
import {
  MIN_DATE_RANGE,
  DATA_ERRORS,
  FILTERS,
  TOKEN_REFRESH_INTERVAL,
} from '@configs';
import { getDateDifference, getStartOfMonth } from '@utils';
import { LoadingLogo } from '@components';

import MetricCount from './components/v2/MetricCount';
import SectionHeading from './components/v2/SectionHeading';
import TopTracks from './components/v2/TopTracks';
import DashboardHeader, {
  dashboardTypes,
} from './components/v2/DashboardHeader';
import TopGenres from './components/v2/TopGenres';
import EventsList from './components/v2/EventsList';
import EmptyContent from './components/v2/EmptyContent';
import { updateBreadcrumbs } from './components/v2/DashboardBreadcrumb';

const IS_REFRESH_DATE =
  localStorage.getItem('is_refresh') &&
  JSON.parse(localStorage.getItem('is_refresh'));

const IS_START_DATE =
  localStorage.getItem('start_date') && localStorage.getItem('start_date');
const IS_END_DATE =
  localStorage.getItem('end_date') && localStorage.getItem('end_date');

const INITIAL_DATE =
  (IS_REFRESH_DATE &&
    IS_START_DATE &&
    new Date(Number(localStorage.getItem('start_date')))) ||
  getDateDifference(3);

const END_DATE =
  (IS_REFRESH_DATE &&
    IS_END_DATE &&
    new Date(Number(localStorage.getItem('end_date')))) ||
  new Date();

export const DeviceDashboard = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const calendarRef = useRef(null);
  const isClickedOutside = useDetectOutsideClick(calendarRef);
  let { id } = useParams();

  const [userData, setUserData] = useState(null);
  const [userFreshToken, setUserFreshToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [dateRange, setDateRange] = useState([INITIAL_DATE, END_DATE]);
  const [newDateRange, setNewDateRange] = useState([INITIAL_DATE, END_DATE]);

  const [error, setError] = useState(null);

  const [isShowCalendar, setIsShowCalendar] = useState(false);

  const [activeFilter, setActiveFilter] = useState('Last 3 months');

  const fetchToken = async () => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken(true);
      localStorage.setItem('user_access_token', token);
      return token;
    }
    return null;
  };

  const dataParams = useMemo(() => {
    const params = {
      user_id: userData?.user_id || localStorage.getItem('user_id'),
      access_token: userFreshToken || localStorage.getItem('user_access_token'),

      start_date: new Date(newDateRange[0])?.getTime(),
      end_date: new Date(newDateRange[1])?.getTime(),
      device_id: id,
    };
    return params;
  }, [newDateRange, id, userFreshToken, userData?.user_id]);

  const dashboardData = useDeviceDashboardData(dataParams);

  const dashboard_response_status =
    dashboardData && dashboardData?.responseStatus;
  const dashboard_data = dashboardData && dashboardData?.data;

  useEffect(() => {
    const element = document.getElementById('root');
    if (element) {
      element.scrollIntoView();
    }
  }, [location]);

  useEffect(() => {
    if (dashboardData && dashboardData.responseStatus === 200) {
      getLastUpdateTime();
      setIsLoading(false);
      if (id) {
        const breadcrumbDataWithDeviceName = [
          { key: 1, label: 'Home', href: '/' },
          { key: 2, label: dashboardData?.data?.device_name },
        ];
        updateBreadcrumbs(breadcrumbDataWithDeviceName);
      } else {
        updateBreadcrumbs([{ key: 1, label: 'Home' }]);
      }
      if (DATA_ERRORS.includes(dashboardData.status)) {
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_access_token');
        navigate('/login');
      }
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard_response_status, dashboard_data]);

  useEffect(() => {
    setIsLoading(true);
    getRefreshedTokenAndUpdateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isClickedOutside && dateRange !== newDateRange) {
      setDateRange(newDateRange);
    }
    if (isClickedOutside) {
      setIsShowCalendar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClickedOutside]);

  useEffect(() => {
    const id = setInterval(
      getRefreshedTokenAndUpdateUser,
      TOKEN_REFRESH_INTERVAL
    );
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLastUpdateTime = () => {
    const d = new Date();
    const updateTime = `${d
      .toDateString()
      .split(' ')
      .slice(1)
      .join(' ')} ${d.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })} `;
    setLastUpdateTime(updateTime);
  };

  const handleToggleCalendar = () => {
    setIsShowCalendar(!isShowCalendar);
  };

  const getRefreshedTokenAndUpdateUser = () => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const idToken = await user.getIdToken(true);
          localStorage.setItem('user_access_token', idToken);
          setUserFreshToken(idToken);
          const userData = {
            access_token: idToken,
            email: user.email,
          };
          const { data } = await getUser(userData);
          localStorage.setItem('user_id', data.user_id);
          setUserData(data);
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      } else {
        console.log('unauthorized');
        navigate('/login');
      }
    });
  };
  const handleSearchBtn = async () => {
    setIsShowCalendar(false);
    setNewDateRange(dateRange);
    localStorage.setItem('start_date', new Date(dateRange[0]).getTime());
    localStorage.setItem('end_date', new Date(dateRange[1]).getTime());
    setActiveFilter('');
    setIsLoading(true);

    // Fetch new token and update params
    await fetchToken();
  };

  const onChangeDateRange = (value) => {
    setDateRange(value);
  };

  const onClickDateFilters = (filter) => {
    setIsLoading(true);
    let startDate = '';
    if (filter === 'This month') {
      startDate = getStartOfMonth();
    }

    if (filter === 'Last 3 months') {
      startDate = getDateDifference(3);
    }

    if (filter === 'Last 6 months') {
      startDate = getDateDifference(6);
    }

    if (filter === 'All Events') {
      startDate = 1704067200000;
    }
    setActiveFilter(filter);
    localStorage.setItem('start_date', new Date(startDate).getTime());
    localStorage.setItem('end_date', new Date().getTime());

    setDateRange([startDate, new Date()]);
    setNewDateRange([startDate, new Date()]);
  };

  const calendarProps = {
    isShowCalendar,
    handleToggleCalendar,
    dateRange,
    onChangeDateRange,
    handleSearchBtn,
    calendarRef,
    MIN_DATE_RANGE,
  };

  const filtersProps = {
    FILTERS,
    activeFilter,
    onClickDateFilters,
  };

  return (
    <Box>
      {isLoading && <LoadingLogo />}

      <Box>
        <Box>
          <Container
            maxWidth="xl"
            sx={{ padding: '0px 0px 60px 0px !important', height: '100%' }}
          >
            <DashboardHeader
              userData={userData}
              lastUpdateTime={lastUpdateTime}
              calendarProps={calendarProps}
              filtersProps={filtersProps}
              dashboardType={
                id ? dashboardTypes.deviceFromGroup : dashboardTypes.device
              }
              deviceName={dashboardData?.data?.device_name}
              hasFilterOptions
            />

            <Box mt={'54px'}>
              <SectionHeading
                headingText={dashboardData?.data?.metrics.title}
                tooltipText={dashboardData?.data?.metrics.info}
              />
              {dashboardData?.data?.metrics.object && (
                <MetricCount
                  metrics={Object.entries(dashboardData.data?.metrics.object)
                    .filter(([key, _]) => key.startsWith('object'))
                    .map(([_, value]) => value)}
                  chartData={dashboardData.data?.metrics.object.chart_data}
                />
              )}
            </Box>

            <Box mt="5rem">
              <Grid container spacing={'4rem'}>
                <Grid item xs={12} md={6}>
                  <SectionHeading
                    headingText={dashboardData?.data?.section_1.title}
                    tooltipText={dashboardData?.data?.section_1.info}
                  />
                  {dashboardData?.data?.section_1 && (
                    <TopTracks tracks={dashboardData.data?.section_1.object} />
                  )}
                </Grid>

                <Grid item xs={12} md={6}>
                  <SectionHeading
                    headingText={dashboardData?.data?.section_2.title}
                    tooltipText={dashboardData?.data?.section_2.info}
                  />

                  {!dashboardData?.data?.section_2.object?.length && (
                    <EmptyContent text="There are no genres in the list." />
                  )}

                  {dashboardData?.data?.section_2.object.length ? (
                    <TopGenres data={dashboardData?.data?.section_2.object} />
                  ) : null}
                </Grid>
              </Grid>
            </Box>

            <Box mt="5rem">
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <SectionHeading
                      headingText={dashboardData?.data?.section_3.title}
                      tooltipText={dashboardData?.data?.section_3.info}
                    />

                    <Box mb="2rem">
                      <Button variant="outlined" disabled>
                        Download
                      </Button>
                    </Box>
                  </Box>
                  {dashboardData?.data?.section_3.object && (
                    <EventsList
                      data={dashboardData?.data?.section_3.object || []}
                      associatedDevice={{
                        deviceUrl: window.location.href,
                        deviceName: dashboardData?.data?.device_name,
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>

      <Box className="error-alert">
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={5000}
          open={error}
          onClose={() => setError(null)}
          message={error}
        />
      </Box>
    </Box>
  );
};
