import React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  Avatar,
} from '@mui/material';
import { format } from 'date-fns';

const columnHelper = createColumnHelper();
const placeholderImage = '/no-cover-image.png';

const columns = [
  columnHelper.accessor('time_recorded', {
    cell: (info) => format(new Date(info.getValue()), 'dd/MM/yy HH:mm:ss'),
    header: 'Time',
  }),
  columnHelper.accessor('cover_image', {
    cell: (info) => (
      <Avatar
        variant="rounded"
        src={
          info.getValue() && info.getValue() !== '-'
            ? info.getValue()
            : placeholderImage
        }
        alt="Cover"
        onError={(event) => (event.target.src = placeholderImage)}
      />
    ),
    header: '',
  }),
  columnHelper.accessor('title', {
    cell: (info) => (
      <Box
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '300px',
        }}
      >
        {info.getValue()}
      </Box>
    ),
    header: 'Title',
  }),
  columnHelper.accessor('artist', {
    cell: (info) => info.getValue(),
    header: 'Artist',
  }),
  columnHelper.accessor('duration', {
    cell: (info) => {
      const durationMs = info.getValue();
      const minutes = Math.floor(durationMs / 60000);
      const seconds = Math.floor((durationMs % 60000) / 1000);
      if (minutes === 0) {
        return `${seconds}s`;
      }
      return `${minutes}m ${seconds}s`;
    },
    header: 'Duration',
  }),
  columnHelper.accessor('genres', {
    cell: (info) => info.getValue().join(', '),
    header: 'Genres',
  }),
  columnHelper.accessor('isrc', {
    cell: (info) => info.getValue(),
    header: 'ISRC',
  }),
];

const TrackList = ({ data }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const renderHeader = () => {
    return (
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableCell key={header.id} style={{ padding: '0.6rem 1.2rem' }}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
    );
  };

  return (
    <TableContainer
      component={Box}
      borderRadius={'0.8rem'}
      padding="5rem"
      bgcolor={'background.section'}
    >
      <Table sx={{ height: '100%' }}>
        {renderHeader()}
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} style={{ border: 'none' }}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} style={{ padding: '0.6rem 1.2rem' }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TrackList;
