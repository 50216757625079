import axios from 'axios';

const baseURL =
  process.env.REACT_APP_ENV === 'PROD'
    ? process.env.REACT_APP_SERVER_ADDRESS
    : process.env.REACT_APP_SERVER_ADDRESS_DEV;

const headers = {};

export const axiosClient = () =>
  axios.create({
    headers,
    baseURL,
  });
