import React from "react";

import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";

import { Button } from "../Button";

import "./index.css";

export const Modal = ({
  title,
  label,
  open,
  isLogoutModal,
  onClose,
  onClick,
  isLoading,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      keepMounted
      className="confirmation-modal"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        className={`confirmation-buttons ${
          isLogoutModal ? "reverse-btns-order" : ""
        }`}
      >
        {isLoading ? (
          <Box className="vollou-circular-progress modal-progress">
            <CircularProgress />
            <span className="modal-wait-text">Please wait...</span>
          </Box>
        ) : (
          <Button
            variant={isLogoutModal ? "secondary" : "outlined"}
            className={`modal-btns ${
              isLogoutModal ? "" : "modal-outlined-btn"
            }`}
            onClick={onClick}
          >
            {label}
          </Button>
        )}
        <Button
          variant={isLogoutModal ? "outlined" : "secondary"}
          className={`modal-btns ${isLogoutModal ? "modal-outlined-btn" : ""}`}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
};
