import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

import TooltipComponent from '../Tooltip';
import MetricChart from './MetricChart';

const MetricCount = ({ metrics, chartData }) => {
  const data =
    chartData &&
    chartData.map((item) => ({
      name: item.date,
      tracks: item.tracks_recorded,
    }));

  const generateDurationValue = (value) => {
    const durationMs = value;
    const totalMinutes = Math.floor(durationMs / 60000);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours > 0 ? hours + 'h ' : ''}${minutes}m`;
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.section',
        color: '#ffffff',
        borderRadius: '8px',
      }}
    >
      <Grid container>
        {metrics.map((metric, index) => (
          <Grid key={metric.name} item xs={3}>
            <Box
              textAlign="center"
              position="relative"
              pt="22px"
              pr="20px"
              pb="35px"
              borderRight={index !== metrics.length - 1 ? '1px solid' : 'none'}
              borderColor="rgba(224, 224, 224, 0.2)"
            >
              <Box display="flex" justifyContent="flex-end">
                <TooltipComponent title={metric.info} />
              </Box>
              <Typography variant="h1" color="secondary.main">
                {metric.name === 'Duration'
                  ? generateDurationValue(metric.value)
                  : metric.value.toLocaleString()}
              </Typography>
              <Typography variant="body1" color="primary.main" mt="12px">
                {metric.name}
              </Typography>
            </Box>
          </Grid>
        ))}

        {data && (
          <Grid item xs={12}>
            <Box p={2} height={300}>
              <MetricChart data={data} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default MetricCount;
