import React, { useState } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  Checkbox,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { format } from 'date-fns';
import LiveBadge from '../LiveBadge';

const columnHelper = createColumnHelper();

const columns = [
  {
    id: 'select-col',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
        onClick={(e) => e.stopPropagation()} // Prevent event propagation to row
      />
    ),
    enableResizing: false, //disable resizing for just this column
    size: 50, //starting column size
    maxSize: 50,
  },
  columnHelper.accessor('start_time', {
    cell: (info) => {
      const live = info.row.original.live;
      return (
        <Box display="flex" alignItems="center" gap={1}>
          {format(new Date(info.getValue()), 'HH:mm d MMMM yyyy')}
          {live && (
            <Box>
              {' '}
              <LiveBadge />{' '}
            </Box>
          )}
        </Box>
      );
    },
    header: 'Start Date',
  }),
  columnHelper.accessor('location', {
    cell: (info) => info.getValue(),
    header: 'Location',
  }),
  columnHelper.accessor('track_count', {
    cell: (info) => info.getValue(),
    header: 'Tracks',
  }),
  columnHelper.accessor('genres', {
    cell: (info) =>
      info.getValue().length === 0 ? '-' : info.getValue().join(', '),
    header: 'Genres',
  }),
  columnHelper.accessor('event_length', {
    cell: (info) => {
      const durationMs = info.getValue();
      const totalMinutes = Math.floor(durationMs / 60000);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      return `${hours > 0 ? hours + 'h ' : ''}${minutes}m`;
    },
    header: 'Length',
  }),

  columnHelper.display({
    id: 'arrow',
    cell: () => <ArrowForwardIosIcon />,
    header: '', // No header for this column
  }),
];

const EventsList = ({ data, associatedDevice }) => {
  const [rowSelection, setRowSelection] = useState({});
  const navigate = useNavigate();

  const table = useReactTable({
    data,
    columns,
    state: { rowSelection },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
  });

  const renderHeader = () => {
    return (
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableCell
                key={header.id}
                style={{ width: `${header.column.getSize()}px` }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
    );
  };

  return (
    <TableContainer
      component={Box}
      borderRadius={'0.8rem'}
      pt={'1.8rem'}
      pb={'5rem'}
      bgcolor={'background.section'}
    >
      <Table sx={{ height: '100%', tableLayout: 'fixed' }}>
        {renderHeader()}
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              onClick={() =>
                navigate(`/event/${row.original.event_id}`, {
                  state: {
                    eventId: row.original.event_id,
                    associatedDevice,
                  },
                })
              }
              sx={{
                cursor: 'pointer',
                backgroundColor: row.getIsSelected()
                  ? 'rgba(0, 0, 255, 0.1)'
                  : 'inherit',
              }}
              hover
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  style={{
                    width: cell.column.getSize(),
                    maxWidth: cell.column.getSize(),
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EventsList;
