import React from "react";
import "./index.css";

export const Button = ({ children, className, variant, ...props }) => {
  const getClassName = () => {
    if (variant === "contained") {
      return "contained-button";
    }

    if (variant === "outlined") {
      return "outlined-button";
    }

    if (variant === "secondary") {
      return "secondary-button";
    }

    if (variant === "primary") {
      return "primary-button";
    }
  };

  return (
    <button
      {...props}
      className={`button-text ${className ? className : ""} ${getClassName()}`}
    >
      {children}
      <div className="button-overlay" />
    </button>
  );
};
