import React from 'react';

import { Box } from '@mui/material';

const EmptyContent = ({ text }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="39rem"
      borderRadius="0.8rem"
      bgcolor="background.section"
      color="rgba(255, 255, 255, 0.7)"
      fontSize="1.6rem"
      fontWeight="500"
    >
      {text}
    </Box>
  );
};

export default EmptyContent;
