import React from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { format, formatDistanceToNow } from 'date-fns';

const columnHelper = createColumnHelper();

const StatusCell = ({ status }) => {
  const isOnline = status === 'online';
  const color = isOnline ? '#009E10' : '#B31037';
  const text = isOnline ? 'Online' : 'Offline';

  return (
    <Box display="flex" alignItems="center">
      <Box
        component="span"
        sx={{
          width: '1.4rem',
          height: '1.4rem',
          borderRadius: '50%',
          backgroundColor: color,
          marginRight: '1rem',
        }}
      />
      {text}
    </Box>
  );
};

const columns = [
  columnHelper.accessor('status', {
    cell: (info) => {
      return <StatusCell status={info.getValue()} />;
    },
    header: 'Status',
  }),
  columnHelper.accessor('name', {
    cell: (info) => info.getValue(),
    header: 'Name',
  }),
  columnHelper.accessor('location', {
    cell: (info) => info.getValue(),
    header: 'Location',
  }),
  columnHelper.accessor('events', {
    cell: (info) => info.getValue(),
    header: 'Events',
  }),
  columnHelper.accessor('setup_date', {
    cell: (info) => format(new Date(info.getValue()), 'dd/MM/yy'),
    header: 'Setup',
  }),
  columnHelper.accessor('last_event', {
    cell: (info) =>
      info.getValue() !== '-'
        ? formatDistanceToNow(new Date(info.getValue()), { addSuffix: true })
        : '-',
    header: 'Last Event',
  }),
  columnHelper.display({
    id: 'arrow',
    cell: () => <ArrowForwardIosIcon />,
    header: '', // No header for this column
  }),
];

const Devices = ({ data }) => {
  const navigate = useNavigate();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const renderHeader = () => {
    return (
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => (
              <TableCell
                key={header.id}
                sx={{
                  paddingLeft: index === 0 ? '6rem' : 'default',
                  paddingRight:
                    index === table.getHeaderGroups().length - 1
                      ? '6rem'
                      : 'default',
                }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
    );
  };

  return (
    <Box
      borderRadius={'0.8rem'}
      pt={'4.2rem'}
      pb={'5rem'}
      bgcolor={'background.section'}
    >
      <TableContainer>
        <Table sx={{ height: '100%' }}>
          {renderHeader()}
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                onClick={() => navigate(`/device/${row.original.device_id}`)}
                sx={{
                  cursor: 'pointer',
                }}
                hover
              >
                {row.getVisibleCells().map((cell, index) => (
                  <TableCell
                    key={cell.id}
                    sx={{
                      paddingLeft: index === 0 ? '6rem' : 'default',
                      paddingRight:
                        index === row.getVisibleCells().length - 1
                          ? '6rem'
                          : 'default',
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Devices;
