import useSWRImmutable from 'swr';
import { axiosClient } from '../axiosClient';
import { API_ENDPOINTS, DATA_REFETCH_INTERVAL } from '@configs';

const fetcher = (url, data) => {
  return axiosClient()
    .post(url, data)
    .then((res) => res);
};

export const useDeviceDashboardData = (dataa) => {
  const { data, mutate } = useSWRImmutable(
    [API_ENDPOINTS.DEVICE_DASHBOARD_DATA.API, dataa],
    fetcher,
    {
      refreshInterval: DATA_REFETCH_INTERVAL,
      revalidateOnFocus: false,
    }
  );
  if (data) {
    return {
      data: data.data.data,
      status: data.data.status,
      responseStatus: data.status,
      mutate: mutate,
    };
  }
};
