import Map, { Marker } from 'react-map-gl';

import { Box } from '@mui/material';

const LocationMap = ({ data }) => {
  const { longitude, latitude } = data.location_coordinates;

  return (
    <Box
      position="relative"
      width="100%"
      height="39rem"
      overflow="hidden"
      border="1px solid #E0E0E0"
      borderRadius="0.8rem"
    >
      <Map
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_KEY}
        initialViewState={{
          longitude,
          latitude,
          zoom: 14,
        }}
        style={{ width: '100%', height: '100%' }}
        mapStyle="mapbox://styles/mapbox/dark-v11"
      >
        <Marker longitude={longitude} latitude={latitude} anchor="bottom">
          <Box textAlign="center">
            <Box
              component="img"
              src="/map-marker.svg"
              alt="marker"
              sx={{
                display: 'block',
                margin: '0 auto',
              }}
            />
            <Box
              sx={{
                color: '#E0E0E0',
                backgroundColor: 'rgba(15, 15, 15, 0.7)',
                padding: '2px 8px',
                borderRadius: '8px',
                marginTop: '5px',
                fontSize: '14px',
                fontWeight: 400,
              }}
            >
              {data.location_name}
            </Box>
          </Box>
        </Marker>
      </Map>
    </Box>
  );
};

export default LocationMap;
