import React, { useState, useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export const BreadcrumbItemStyle = {
  fontSize: '1.4rem',
  display: 'flex',
  alignItems: 'center',
  color: 'rgba(117, 117, 117, 1)',
};

// Utility to get breadcrumbs from localStorage
export const getBreadcrumbsFromStorage = () => {
  const data = localStorage.getItem('breadcrumbs');
  return data ? JSON.parse(data) : [];
};

// Utility to set breadcrumbs in localStorage
export const setBreadcrumbsToStorage = (breadcrumbs) => {
  localStorage.setItem('breadcrumbs', JSON.stringify(breadcrumbs));
};

// Function to update breadcrumbs externally
export const updateBreadcrumbs = (newBreadcrumbs) => {
  setBreadcrumbsToStorage(newBreadcrumbs);
  window.dispatchEvent(new Event('breadcrumbsUpdated'));
};

const DashboardBreadcrumb = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  // Effect to listen for localStorage changes and update state
  useEffect(() => {
    const storedBreadcrumbs = getBreadcrumbsFromStorage();
    setBreadcrumbs(storedBreadcrumbs);
    // Listener for when 'breadcrumbsUpdated' event is dispatched
    const handleBreadcrumbsUpdate = () => {
      const updatedBreadcrumbs = getBreadcrumbsFromStorage();
      setBreadcrumbs(updatedBreadcrumbs);
    };

    window.addEventListener('breadcrumbsUpdated', handleBreadcrumbsUpdate);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('breadcrumbsUpdated', handleBreadcrumbsUpdate);
    };
  }, []);

  return (
    <Stack>
      <Breadcrumbs
        separator={
          <NavigateNextIcon
            style={{
              color: 'rgba(117, 117, 117, 1)',
              fontSize: '1.4rem',
            }}
          />
        }
        sx={{
          '& .MuiBreadcrumbs-separator': {
            marginRight: '0.2rem',
            marginLeft: '0.2rem',
          },
        }}
        aria-label="dashboard-breadcrumb"
      >
        {breadcrumbs.map((breadcrumb, index) =>
          breadcrumb.href ? (
            <Link
              underline="hover"
              key={breadcrumb.key}
              href={breadcrumb.href}
              sx={BreadcrumbItemStyle}
            >
              {breadcrumb.label}
            </Link>
          ) : (
            <Typography key={breadcrumb.key} sx={BreadcrumbItemStyle}>
              {breadcrumb.label}
            </Typography>
          )
        )}
      </Breadcrumbs>
    </Stack>
  );
};

export default DashboardBreadcrumb;
