import { axiosClient } from '../axiosClient';

import { API_ENDPOINTS } from '@configs';

export const getUser = async (data) => {
  return await axiosClient()
    .post(API_ENDPOINTS.USER.API, data)
    .then((response) => {
      return {
        data: response.data.data,
        status: response.data.status,
        responseStatus: response.status,
      };
    });
};
