import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Box } from '@mui/material';
import { getUser } from '@apis';

import { Header } from '@components';
import BetaBanner from '../BetaBanner';
import { accountTypes } from '../../constants/accountTypes';
import { headerZIndex } from '../../constants/styleConfigs';

const Layout = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUser = () => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then(async (idToken) => {
          const userData = {
            access_token: idToken,
            email: user.email,
          };
          const { data } = await getUser(userData);
          if (data.account_type === accountTypes.DEVICE) {
            navigate('/device');
          }
          setUserData(data);
        });
      }
    });
  };

  return (
    <Box position="relative">
      <Box>
        <Box position="fixed" zIndex={headerZIndex}>
          <BetaBanner />
          <Header
            name={userData?.username}
            image={userData?.profile_image}
            background="#0f0f0f"
            justifyContent="justify-dashboard-header"
          />
        </Box>

        <main style={{ paddingTop: '13.8rem' }}>{children}</main>
      </Box>
    </Box>
  );
};

export default Layout;
