export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const DATA_REFETCH_INTERVAL = 300000;
export const TOKEN_REFRESH_INTERVAL = 250000;
export const IS_MBL_DEVICE = window.innerWidth < 600;
export const IS_TABLET_DEVICE = window.innerWidth < 1200;
export const MIN_DATE_RANGE = '01/02/2022';

export const FILTERS = [
  'This month',
  'Last 3 months',
  'Last 6 months',
  'All Events',
];

export const DATA_ERRORS = [
  'Invalid request authentication',
  'Access token not provided',
  'User is not permitted to access event dashboard',
  'User not found',
];
