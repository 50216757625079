import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';

const MetricChart = ({ data }) => {
  const yMax = Math.max(...data.map((item) => item.tracks));
  const yMin = Math.min(...data.map((item) => item.tracks));
  const yInterval = (yMax - yMin) / 5;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="name"
          tick={{ fill: '#757575', fontSize: '12px' }}
          tickLine={false}
        />
        <YAxis tick={{ fill: '#757575', fontSize: '12px' }} tickLine={false} />
        <Tooltip
          contentStyle={{
            backgroundColor: '#1B2130',
            border: 'none',
            color: '#E0E0E0',
            textAlign: 'center',
          }}
          itemStyle={{ color: '#E0E0E0', fontSize: '10px', fontWeight: 400 }}
          labelStyle={{ color: '#E0E0E0', fontSize: '20px', fontWeight: 700 }}
        />
        {Array.from({ length: 6 }).map((_, index) => (
          <ReferenceLine
            key={index}
            y={yMin + yInterval * index}
            stroke="#757575"
          />
        ))}
        <Line
          type="monotone"
          dataKey="tracks"
          stroke="#FF4A4A"
          strokeWidth={3}
          dot={(props) => (props.value === 0 ? null : <circle {...props} />)}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default MetricChart;
