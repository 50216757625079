import React from 'react';
import ReactDOM from 'react-dom/client';

import { isMobile } from 'react-device-detect';

import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import 'react-calendar/dist/Calendar.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import 'mapbox-gl/dist/mapbox-gl.css';

import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import MobileMessage from './components/MobileMessage';

async function enableMocking() {
  if (process.env.ENV !== 'development') {
    return;
  }

  // const { worker } = await import('./mocks/browser');

  // return worker.start();
}

enableMocking().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      {isMobile ? <MobileMessage /> : <App />}
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
