export const API_ENDPOINTS = {
  USER: {
    API: '/dashboard/getUser',
  },
  GET_EVENTS: {
    API: '/dashboard/event/getAllEvents',
  },
  EVENTS_SEARCH: {
    API: '/dashboard/event/searchEvents',
  },
  GROUP_DASHBOARD_DATA: {
    API: '/dashboard/getGroupDashboard',
  },
  DEVICE_DASHBOARD_DATA: {
    API: '/dashboard/getDeviceDashboard',
  },
  EVENT_DASHBOARD_DATA: {
    API: '/dashboard/getEventDashboard',
  },
};
