import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Box, Container, Snackbar, Grid } from '@mui/material';

import { getUser, useGroupDashboardData } from '@apis';
import { useDetectOutsideClick } from '@hooks';
import { MIN_DATE_RANGE, FILTERS, TOKEN_REFRESH_INTERVAL } from '@configs';
import { getDateDifference, getStartOfMonth } from '@utils';
import { LoadingLogo } from '@components';

import MetricCount from './components/v2/MetricCount';
import SectionHeading from './components/v2/SectionHeading';
import TopTracks from './components/v2/TopTracks';
import LatestEvents from './components/v2/LatestEvents';
import Devices from './components/v2/Devices';
import DashboardHeader, {
  dashboardTypes,
} from './components/v2/DashboardHeader';

import './index.css';
import EmptyContent from './components/v2/EmptyContent';
import { updateBreadcrumbs } from './components/v2/DashboardBreadcrumb';

const IS_REFRESH_DATE =
  localStorage.getItem('is_refresh') &&
  JSON.parse(localStorage.getItem('is_refresh'));

const IS_START_DATE =
  localStorage.getItem('start_date') && localStorage.getItem('start_date');
const IS_END_DATE =
  localStorage.getItem('end_date') && localStorage.getItem('end_date');

const INITIAL_DATE =
  (IS_REFRESH_DATE &&
    IS_START_DATE &&
    new Date(Number(localStorage.getItem('start_date')))) ||
  getDateDifference(3);

const END_DATE =
  (IS_REFRESH_DATE &&
    IS_END_DATE &&
    new Date(Number(localStorage.getItem('end_date')))) ||
  new Date();

export const GroupDashboard = () => {
  const auth = getAuth();
  const calendarRef = useRef(null);
  const isClickedOutside = useDetectOutsideClick(calendarRef);
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const [userFreshToken, setUserFreshToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [dateRange, setDateRange] = useState([INITIAL_DATE, END_DATE]);
  const [newDateRange, setNewDateRange] = useState([INITIAL_DATE, END_DATE]);

  const [error, setError] = useState(null);
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [activeFilter, setActiveFilter] = useState('Last 3 months');

  const dataParams = useMemo(() => {
    const params = {
      user_id: userData?.user_id || localStorage.getItem('user_id'),
      access_token: userFreshToken || localStorage.getItem('user_access_token'),
      start_date: new Date(newDateRange[0])?.getTime(),
      end_date: new Date(newDateRange[1])?.getTime(),
    };
    return params;
  }, [newDateRange, userData?.user_id, userFreshToken]);
  const breadcrumbData = [{ key: 1, label: 'Home' }];

  const dashboardData = useGroupDashboardData(dataParams);

  const metricsData = dashboardData?.data?.metrics;
  const topPlayedTracksData = dashboardData?.data?.section_1;
  const latestEventsData = dashboardData?.data?.section_2;
  const devicesData = dashboardData?.data?.section_3;
  // const fetchDashboardData = dashboardData?.mutate;

  useEffect(() => {
    if (dashboardData && dashboardData.responseStatus === 200) {
      getLastUpdateTime();
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [dashboardData]);

  useEffect(() => {
    setIsLoading(true);
    getRefreshedTokenAndUpdateUser();
    updateBreadcrumbs(breadcrumbData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const id = setInterval(
      getRefreshedTokenAndUpdateUser,
      TOKEN_REFRESH_INTERVAL
    );
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isClickedOutside && dateRange !== newDateRange) {
      setDateRange(newDateRange);
    }
    if (isClickedOutside) {
      setIsShowCalendar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClickedOutside]);

  const getRefreshedTokenAndUpdateUser = () => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const idToken = await user.getIdToken(false);
          setUserFreshToken(idToken);
          localStorage.setItem('user_access_token', idToken);
          const userData = {
            access_token: idToken,
            email: user.email,
          };
          const { data } = await getUser(userData);
          localStorage.setItem('user_id', data.user_id);

          setUserData(data);
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      } else {
        console.log('unauthorized');
        navigate('/login');
      }
    });
  };

  const getLastUpdateTime = () => {
    const d = new Date();
    const updateTime = `${d
      .toDateString()
      .split(' ')
      .slice(1)
      .join(' ')} ${d.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })} `;
    setLastUpdateTime(updateTime);
  };

  const handleToggleCalendar = () => {
    setIsShowCalendar(!isShowCalendar);
  };

  const handleSearchBtn = () => {
    setIsShowCalendar(false);
    setNewDateRange(dateRange);
    localStorage.setItem('start_date', new Date(dateRange[0]).getTime());
    localStorage.setItem('end_date', new Date(dateRange[1]).getTime());
    setActiveFilter('');
    setIsLoading(true);
  };

  const onChangeDateRange = (value) => {
    setDateRange(value);
  };

  const onClickDateFilters = (filter) => {
    setIsLoading(true);
    let startDate = '';
    if (filter === 'This month') {
      startDate = getStartOfMonth();
    }

    if (filter === 'Last 3 months') {
      startDate = getDateDifference(3);
    }

    if (filter === 'Last 6 months') {
      startDate = getDateDifference(6);
    }

    if (filter === 'All Events') {
      startDate = 1704067200000;
    }
    setActiveFilter(filter);
    localStorage.setItem('start_date', new Date(startDate).getTime());
    localStorage.setItem('end_date', new Date().getTime());

    setDateRange([startDate, new Date()]);
    setNewDateRange([startDate, new Date()]);
  };

  const calendarProps = {
    isShowCalendar,
    handleToggleCalendar,
    dateRange,
    onChangeDateRange,
    handleSearchBtn,
    calendarRef,
    MIN_DATE_RANGE,
  };

  const filtersProps = {
    FILTERS,
    activeFilter,
    onClickDateFilters,
  };

  return (
    <Box>
      {isLoading && <LoadingLogo />}

      <Box>
        <Container
          maxWidth="xl"
          sx={{ padding: '0px 0px 60px 0px !important', height: '100%' }}
        >
          <DashboardHeader
            userData={userData}
            lastUpdateTime={lastUpdateTime}
            calendarProps={calendarProps}
            filtersProps={filtersProps}
            dashboardType={dashboardTypes.group}
            hasFilterOptions
          />

          <Box mt={'54px'}>
            <SectionHeading
              headingText={metricsData?.title}
              tooltipText={metricsData?.info}
            />
            {metricsData?.object && (
              <MetricCount metrics={Object.values(metricsData?.object)} />
            )}
          </Box>

          <Box mt="5rem">
            <Grid container spacing={'4rem'}>
              <Grid item xs={12} md={6}>
                <SectionHeading
                  headingText={topPlayedTracksData?.title}
                  tooltipText={topPlayedTracksData?.info}
                />
                {topPlayedTracksData && (
                  <TopTracks tracks={topPlayedTracksData?.object} />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <SectionHeading
                  headingText={latestEventsData?.title}
                  tooltipText={latestEventsData?.info}
                />

                {!latestEventsData?.object?.length && (
                  <EmptyContent text="There are no event in the list." />
                )}

                {latestEventsData?.object?.length !== 0 && (
                  <LatestEvents data={latestEventsData?.object || []} />
                )}
              </Grid>
            </Grid>
          </Box>

          <Box mt="5rem">
            <Grid container>
              <Grid item xs={12} md={12}>
                <SectionHeading
                  headingText={devicesData?.title}
                  tooltipText={devicesData?.info}
                />

                {!devicesData?.object.length && (
                  <EmptyContent text="There are no devices found in the group." />
                )}

                {devicesData?.object?.length !== 0 && (
                  <Devices data={devicesData?.object || []} />
                )}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Box className="error-alert">
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={5000}
          open={error}
          onClose={() => setError(null)}
          message={error}
        />
      </Box>
    </Box>
  );
};
