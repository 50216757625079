import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { Box, Container, Snackbar, Grid, Button } from '@mui/material';

import { getUser, useEventDashboardData } from '@apis';
import { DATA_ERRORS, TOKEN_REFRESH_INTERVAL } from '@configs';
import { LoadingLogo } from '@components';

import MetricCount from './components/v2/MetricCount';
import SectionHeading from './components/v2/SectionHeading';
import TopGenres from './components/v2/TopGenres';
import TrackList from './components/v2/TrackList';
import DashboardHeader, {
  dashboardTypes,
} from './components/v2/DashboardHeader';
import LocationMap from './components/v2/LocationMap';
import EmptyContent from './components/v2/EmptyContent';
import { updateBreadcrumbs } from './components/v2/DashboardBreadcrumb';

export const EventDashboard = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { eventId, associatedDevice } = location.state || {};

  const [userData, setUserData] = useState(null);
  const [userFreshToken, setUserFreshToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(null);

  const dataParams = useMemo(() => {
    const params = {
      user_id: userData?.user_id || localStorage.getItem('user_id'),
      access_token: userFreshToken || localStorage.getItem('user_access_token'),
      event_id: eventId,
    };
    return params;
  }, [eventId, userData?.user_id, userFreshToken]);

  const dashboardData = useEventDashboardData(dataParams);

  const dashboard_response_status =
    dashboardData && dashboardData?.responseStatus;
  const dashboard_data = dashboardData && dashboardData?.data;

  useEffect(() => {
    const element = document.getElementById('root');
    if (element) {
      element.scrollIntoView();
    }
  }, [location]);

  useEffect(() => {
    if (dashboardData) {
      setIsLoading(false);
      if (eventId) {
        const breadcrumbDataWithDeviceName = associatedDevice
          ? [
              { key: 1, label: 'Home', href: '/' },
              {
                key: 2,
                label: associatedDevice.deviceName,
                href: associatedDevice.deviceUrl,
              },
              {
                key: 3,
                label: moment(dashboard_data?.event_date).format(
                  'Do MMMM YYYY'
                ),
              },
            ]
          : [
              { key: 1, label: 'Home', href: '/' },
              {
                key: 2,
                label: moment(dashboard_data?.event_date).format(
                  'Do MMMM YYYY'
                ),
              },
            ];
        updateBreadcrumbs(breadcrumbDataWithDeviceName);
      } else {
        updateBreadcrumbs([{ key: 1, label: 'Home' }]);
      }
      if (DATA_ERRORS.includes(dashboardData.status)) {
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_access_token');
        navigate('/login');
      }
      return;
    }
    if (
      dashboardData &&
      dashboardData.responseStatus === 200 &&
      userFreshToken
    ) {
      fetchDashboardData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard_response_status, dashboard_data, userFreshToken]);

  useEffect(() => {
    setIsLoading(true);
    getRefreshedTokenAndUpdateUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const id = setInterval(
      getRefreshedTokenAndUpdateUser,
      TOKEN_REFRESH_INTERVAL
    );
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDashboardData = useCallback(() => {
    localStorage.setItem('is_refresh', false);
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData]);

  const getRefreshedTokenAndUpdateUser = () => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const idToken = await user.getIdToken(true);
          setUserFreshToken(idToken);
          localStorage.setItem('user_access_token', idToken);
          const userData = {
            access_token: idToken,
            email: user.email,
          };
          const { data } = await getUser(userData);
          localStorage.setItem('user_id', data.user_id);
          setUserData(data);
        } catch (error) {
          console.error('Error refreshing token:', error);
        }
      } else {
        console.log('unauthorized');
        navigate('/login');
      }
    });
  };

  return (
    <Box>
      {isLoading && <LoadingLogo />}

      <Box>
        <Container
          maxWidth="xl"
          sx={{ padding: '0px 0px 60px 0px !important', height: '100%' }}
        >
          {dashboard_data && (
            <DashboardHeader
              userData={userData}
              hasFilterOptions={false}
              dashboardType={dashboardTypes.event}
              eventDate={dashboard_data?.event_date}
            />
          )}

          <Box mt={'54px'}>
            <SectionHeading
              headingText={dashboardData?.data.metrics.title}
              tooltipText={dashboardData?.data.metrics.info}
            />
            {dashboardData?.data.metrics.object && (
              <MetricCount
                metrics={Object.values(dashboardData.data.metrics.object)}
              />
            )}
          </Box>

          <Box mt="5rem">
            <Grid container spacing={'4rem'}>
              <Grid item xs={12} md={6}>
                <SectionHeading
                  headingText={dashboardData?.data.section_1.title}
                  tooltipText={dashboardData?.data.section_1.info}
                />

                {!dashboardData?.data.section_1.object?.length && (
                  <EmptyContent text="There are no genres in the list." />
                )}

                {dashboardData?.data.section_1.object?.length !== 0 && (
                  <TopGenres data={dashboardData?.data.section_1.object} />
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <SectionHeading
                  headingText={dashboardData?.data.section_2.title}
                  tooltipText={dashboardData?.data.section_2.info}
                />
                {dashboardData?.data.section_2.object && (
                  <LocationMap data={dashboardData?.data.section_2.object} />
                )}
              </Grid>
            </Grid>
          </Box>

          <Box mt="5rem">
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <SectionHeading
                    headingText={dashboardData?.data.section_3.title}
                    tooltipText={dashboardData?.data.section_3.info}
                  />

                  <Box mb="2rem">
                    <Button variant="outlined" disabled>
                      Download Event
                    </Button>
                  </Box>
                </Box>
                {dashboardData?.data.section_3.object && (
                  <TrackList
                    data={dashboardData?.data.section_3.object || []}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Box className="error-alert">
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          autoHideDuration={5000}
          open={error}
          onClose={() => setError(null)}
          message={error}
        />
      </Box>
    </Box>
  );
};
