import React from "react";
import "./index.css";

export const Input = ({ value, error, ...props }) => {
  return (
    <input
      {...props}
      className={`input-field ${value ? "input-filled" : ""} ${
        error ? "input-error" : ""
      }`}
    />
  );
};
