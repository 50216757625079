import { Tooltip as MUITooltip } from '@mui/material';

import InfoIcon from '@mui/icons-material/InfoOutlined';

const Tooltip = ({ title }) => {
  return (
    <MUITooltip
      title={title}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'background.tooltip',
            color: 'rgba(255, 255, 255, 0.6)',
            fontSize: '11px',
          },
        },
      }}
    >
      <InfoIcon
        sx={{
          ml: 1,
          fontSize: '22px',
          verticalAlign: 'middle',
          color: 'primary.main',
        }}
      />
    </MUITooltip>
  );
};

export default Tooltip;
