import { Box } from '@mui/material';

export const SectionCard = ({ children, ...boxSxProps }) => {
  return (
    <Box
      sx={{
        bgcolor: 'background.section',
        color: '#ffffff',
        borderRadius: '8px',
        ...boxSxProps,
      }}
    >
      {children}
    </Box>
  );
};
