import { Box, Typography } from '@mui/material';
import Logo from '../../assets/pngs/Logo.png';

const MobileMessage = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      backgroundColor: 'black',
      paddingLeft: '24px',
      paddingRight: '24px',
    }}
  >
    <Box
      sx={{
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '18px',
      }}
    >
      <img src={Logo} alt="Logo" style={{ width: 'auto', height: '30px' }} />
    </Box>
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1B2130',
        borderRadius: '8px',
        marginBottom: '24px',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: 'white',
          textAlign: 'center',
          maxWidth: '300px',
          fontSize: '20px',
          lineHeight: '32px',
        }}
      >
        Web application only available on desktop right now.
      </Typography>
    </Box>
  </Box>
);

export default MobileMessage;
