export const getDateAndTime = (value) => {
  const currentDay = updateDay(value);
  const date = new Date(value);
  const fullDate = getFullDate(date);
  return `${date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })}  ${currentDay ? currentDay : `${fullDate}`}`;
};

export const getDate = (value) => {
  const date = new Date(value);
  const day = date.toLocaleDateString("en-US", { day: "numeric" });
  const month = date.toLocaleDateString("en-US", { month: "short" });
  return `${day} ${month}`;
};

export const getCalendarDate = (value) => {
  const currentDay = updateDay(value);
  const date = new Date(value);
  const fullDate = getFullDate(date);
  return currentDay ? currentDay : fullDate;
};

const updateDay = (value) => {
  const today = new Date().toLocaleDateString();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const currentValue = new Date(value).toLocaleDateString();

  if (today === currentValue) {
    return "Today";
  }

  if (yesterday.toLocaleDateString() === currentValue) {
    return "Yesterday";
  }
  return null;
};

const getFullDate = (value) => {
  const day = value.toLocaleDateString("en-US", { day: "numeric" });
  let month = value.toLocaleDateString("en-US", { month: "short" });
  const year = value.toLocaleDateString("en-US", { year: "numeric" });

  // display 4 letters if month is September or June
  if (month === "Sep") {
    month = "Sept";
  } else if (month === "Jun") {
    month = "June";
  }

  return `${day} ${month} ${year}`;
};
