export const getDateDifference = (monthDifference) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setMonth(yesterday.getMonth() - monthDifference);
  return yesterday;
};

export const getStartOfMonth = () => {
  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  return firstDay;
};
